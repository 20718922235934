import React, { useState } from 'react';
import { Button, Modal, Container, Header, Progress, Segment, Image, Icon } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import Stopwatch from '../../Timers/Stopwatch';
import Countdown from '../../Timers/Countdown';
import { Consts, Styles } from '../../../../res';
import { Separator } from '../../..';
import { getExamTypeString } from '../../../../helpers/examHelpers';
import iconImage from '../../../../res/images/icon.png';
import request from '../../../../api';

const { noExtraSpaces, solidBtn } = Styles;

const ExamHeader = (props) => {
    const { t, course, courseStruct, user,
        Q, Qindex, questions,
        onGoingExam, userOnFinishExam, examFinishingLoading, isMockExam, summary, theme } = props;

    const questionsWithOutCmaFacts = questions.filter(q => !q?.showFactOnly);
    const solved = questionsWithOutCmaFacts.reduce((acc, q) => !!(q.questionTypeId === Consts.QUESTIONS_TYPES.MCQS ? q.answerOptionId : q.answerText) + acc, 0) || 0;
    const flagged = questionsWithOutCmaFacts.reduce((acc, q) => (q.isFlagged) + acc, 0) || 0;
    const [showFinishConfirm, setShowFinishConfirm] = useState(false);
    const [showFinishAllConfirm, setShowFinishAllConfirm] = useState(false);


    const getQUnitTopic = () => {
        let unitName = '', topicName = '';
        for (let i = 0; i < courseStruct.length; i++) {
            for (let j = 0; j < courseStruct[i].topics.length; j++) {
                if (courseStruct[i].topics[j].id === Q.topicId) {
                    topicName = `${courseStruct[i].topics[j].name} (${courseStruct[i].topics[j].rank})`;
                    unitName = `${courseStruct[i].name} (${courseStruct[i].rank})`;
                    return { unitName, topicName };
                }
            }
        }
        return { unitName, topicName };
    };

    const onPauseTimer = (isPause) => {
        const examRoundId = onGoingExam.examRoundId;

        request.post('/student/round/exam/pause', { examRoundId, isPause });
    };

    const renderTimer = () => {

        if (onGoingExam.isViewOnly)
            return <Header as='h2' color='red' textAlign='center'>{t('ViewOnly')}</Header>;

        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {isMockExam ?
                    <Countdown
                        expiryTimestamp={new Date().getTime() + (new Date(onGoingExam.finishDateTime) - new Date())}
                        onExpire={() => userOnFinishExam(onGoingExam)}
                        theme={theme}
                    />
                    : <Stopwatch
                        offsetTimestamp={new Date().getTime() + (new Date().getTime()
                            - new Date(onGoingExam.startDateTime).getTime())
                            - (onGoingExam.totalPauseSeconds * 1000)
                            - (onGoingExam.lastPauseTime ? (new Date().getTime() - new Date(onGoingExam.lastPauseTime).getTime()) : 0)
                        }
                        theme={theme}
                        onPauseChanged={onPauseTimer}
                        isAutoStart={!onGoingExam.lastPauseTime}
                    />}
                <Separator h='1vh' />
            </div>
        );
    };
    const renderPrimaryHeader = () => {
        return (
            <Segment basic style={{ margin: 0, padding: 5, backgroundColor: '#4c4c4c' }} inverted >
                <Container style={{ width: '95vw' }}>
                    <div style={{ textAlign: 'center', display: 'flex', flexDirection: ' row', justifyContent: 'space-between' }}>

                        <div style={noExtraSpaces} >                            <Header inverted as='h5' style={noExtraSpaces}>{`${course.certificateName} (${course.certificateCode})`}</Header>
                            <Header inverted as='h5' style={noExtraSpaces}>{course.name}</Header>
                        </div>

                        <div style={noExtraSpaces} >
                            {renderTimer()}
                        </div>

                        <div style={noExtraSpaces} >
                            {isMockExam && <Progress label={t('Progress')} precision={1} progress='percent' total={questionsWithOutCmaFacts.length} value={solved} color='green' size='small' inverted style={{ backgroundColor: 'wheat', width: '50%', transform: `translateX(${i18next.language === 'ar' ? -50 : 50}%)` }} />}
                        </div>

                        <div style={noExtraSpaces} >
                            <Button
                                fluid
                                negative
                                content={t(onGoingExam.isViewOnly ? 'Exit' : isMockExam ? 'FinishSection' : 'Finish').toUpperCase()}
                                loading={examFinishingLoading}
                                style={{ backgroundColor: 'wheat', color: 'black' }}
                                onClick={() => onGoingExam.isViewOnly ? userOnFinishExam(onGoingExam) : (solved === questionsWithOutCmaFacts.length ? setShowFinishAllConfirm(true) : setShowFinishConfirm(true))}
                            />
                            <Modal
                                size='mini'
                                open={showFinishConfirm}
                                header={<div className='tw-text-white tw-bg-[#4c4c4c] tw-p-2'>{t('CmaConfirmFinish')}</div>}
                                content={<div className='tw-grid tw-text-center tw-p-5 tw-gap-5 tw-my-5'>
                                    <div><Icon name='exclamation triangle' size='huge' className='tw-text-GOLD' /></div>
                                    <div>
                                        {
                                            flagged > 0 ? <p> {t("CmaFinishExamNotAllQuestionswithFlags")} </p> : <p>{t("CmaFinishExamNotAllQuestionsNoFlags")}</p>
                                        }
                                    </div>
                                    <p>{t("CmaAreYouSureFinishExam")}</p>

                                    <div>
                                        <Button icon='x' onClick={() => setShowFinishConfirm(false)} content={t("Exit")} style={{ ...solidBtn, backgroundColor: "#8CC63F" }} />
                                        <Button icon='check' onClick={() => { setShowFinishConfirm(false); setShowFinishAllConfirm(true); }} content={t("Finish")} style={{ ...solidBtn, backgroundColor: "#8CC63F" }} />
                                    </div>
                                </div>}
                            />
                            <Modal
                                size='mini'
                                open={showFinishAllConfirm}
                                header={<div className='tw-text-white tw-bg-[#4c4c4c] tw-p-2'>{t('CmaConfirmFinish')}</div>}
                                content={<div className='tw-grid tw-text-center tw-p-5 tw-gap-5 tw-my-5'>
                                    <div><Icon name='question circle' size='huge' className='tw-text-PRIMARY_50' /> </div>
                                    <div><p>{t("CmaFinishExam")}</p></div>
                                    <div>
                                        <Button icon='x' onClick={() => setShowFinishAllConfirm(false)} content={t("Exit")} style={{ ...solidBtn, backgroundColor: "#8CC63F" }} />
                                        <Button icon='check' onClick={() => { userOnFinishExam(onGoingExam); setShowFinishAllConfirm(false); }} content={t("Finish")} style={{ ...solidBtn, backgroundColor: "#8CC63F" }} />
                                    </div>
                                </div>}
                            />
                        </div>
                    </div>
                </Container>
            </Segment>
        );
    };

    const renderSecondaryHeader = () => {
        const { unitName, topicName } = getQUnitTopic();
        return (
            <Segment basic style={{ margin: 0, padding: 5, backgroundColor: '#8CC63F' }} inverted>
                <Container style={{ width: '95vw' }}>
                    <div style={{ textAlign: 'center', display: 'flex', flexDirection: ' row', justifyContent: 'space-between' }} >
                        <div style={noExtraSpaces} >
                            <Header inverted as='h5' style={noExtraSpaces}>{`${t('Name')}: ${user.firstName} ${user.lastName}`}</Header>
                            <Header inverted as='h5' style={noExtraSpaces}>{`${user.email}`}</Header>
                        </div>

                        <div style={noExtraSpaces} >
                            <Header inverted as='h5' style={noExtraSpaces}>
                                <Image loading="lazy" size='massive' src={iconImage} />
                            </Header>
                            {!isMockExam && <Header inverted as='h5' style={noExtraSpaces}>{unitName}</Header>}
                            {!isMockExam && <Header inverted as='h5' style={noExtraSpaces}>{topicName}</Header>}
                            {isMockExam && <Header inverted as='h5' style={noExtraSpaces}>{getExamTypeString(onGoingExam.examType, onGoingExam.orderNum)}</Header>}
                        </div>

                        <div style={noExtraSpaces} >
                            {!isMockExam && <Header inverted as='h5' style={noExtraSpaces}>{getExamTypeString(onGoingExam.examType, onGoingExam.orderNum)}</Header>}
                            {
                                summary.show ?
                                    <Header inverted as='h5' style={noExtraSpaces}>{t('ReviewAnswers')}</Header>
                                    : <Header inverted as='h5' style={noExtraSpaces}>{Q?.showFactOnly ? `${'Fact'}: ${Q?.qFactIndex + 1}` : `${t('Question')}: ${Qindex + 1 - questions?.slice(0, Qindex)?.filter(q => q?.showFactOnly)?.length} / ${questionsWithOutCmaFacts.length}`}</Header>
                            }
                        </div>
                    </div>
                </Container>
            </Segment>
        );
    };

    if (!Q) return null;

    return (
        <Segment basic style={noExtraSpaces}>
            {renderPrimaryHeader()}
            {renderSecondaryHeader()}
        </Segment>
    );
};

export default withTranslation()(ExamHeader);