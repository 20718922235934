import React, { useState } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { userGetAllCourses } from '../../actions';
import { PaymentForm } from '..';
import { Styles, Theme } from '../../res';
import history from '../../history';
import { savePath } from '../../helpers/savePath';
import PATHS from '../../Routers/PATHS';

const { solidBtn, f_14_700_text } = Styles;

const BuyingButton = (props) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { mode, selectedForHover = 0, data } = props;
    const { discountUSD = 0, priceUSD = 0 } = data;
    const packageId = data.idSubscriptionPackage || parseInt(data.packageId) || data.id;
    const { user } = useSelector(state => state.auth);
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const isFree = (priceUSD - discountUSD) <= 0;

    const renderPrice = () => {
        return discountUSD ? `$${((priceUSD || 0) - (discountUSD || 0)).toFixed(0)}` : `$${(priceUSD || 0).toFixed(0)}`;
    };

    const renderPriceWithDiscount = () => {
        return discountUSD ?
            <>
                <b style={{ ...f_14_700_text, margin: 5, color: selectedForHover ? Theme.colors.WHITE : Theme.colors.PRIMARY_50 }}>
                    {`$${((priceUSD || 0) - (discountUSD || 0)).toFixed(0)}`}
                </b>
                {' '}
                <del style={{ color: 'black' }}>
                    {`$${(priceUSD || 0).toFixed(0)}`}
                </del>
            </>
            : <b style={{ ...f_14_700_text, margin: 5, color: selectedForHover ? Theme.colors.WHITE : Theme.colors.PRIMARY_50 }}>
                {`$${(priceUSD || 0).toFixed(0)}`}
            </b>;
    };

    const onButtonClick = () => {
        if (!user) {
            savePath('buyingPathPref');
            history.push(PATHS.LANDING_SIGNIN.URL);
        }
        else if (!user.isAdmin)
            setShowPaymentForm(true);
    };

    const onBuySuccess = () => {
        setShowPaymentForm(false);
        dispatch(userGetAllCourses());
        history.push(PATHS.USER_DASHBOARD.URL);
    };

    const renderPaymentForm = () => {
        return showPaymentForm && <Modal
            onClose={() => setShowPaymentForm(false)}
            open={showPaymentForm}
            content={<PaymentForm data={{ ...data, id: packageId, user }} onBuySuccess={onBuySuccess} />}
            closeIcon
            size='mini'
            centered
            closeOnDimmerClick={false}
            style={{ borderRadius: 16 }}
        />;
    };


    const render = () => {
        switch (mode) {
            case "PRICE_AS_TEXT": return renderPrice();
            case "PRICE_DISCOUNT_AS_TEXT": return renderPriceWithDiscount();
            case "PRICE_AS_BUTTON": return <Button
                fluid
                size='small'
                content={isFree ? t('JoinForFree') : `${t('Buy').toUpperCase()} ${renderPrice()}`}
                onClick={onButtonClick}
                style={{ ...solidBtn, backgroundColor: selectedForHover ? Theme.colors.SECONDARY_50 : Theme.colors.PRIMARY_50 }}
            />;

            case "NO_PRICE_AS_BUTTON": return <Button
                fluid
                style={!selectedForHover ? solidBtn : { ...solidBtn, backgroundColor: Theme.colors.WHITE, color: Theme.colors.PRIMARY_50 }}
                content={isFree ? t('JoinForFree') : t('Buy').toUpperCase()}
                onClick={onButtonClick}
            />;


            default: return null;
        }
    };

    return (
        <>
            {render()}
            {(mode === "PRICE_AS_BUTTON" || mode === "NO_PRICE_AS_BUTTON") && renderPaymentForm()}
        </>
    );
};

export default BuyingButton;