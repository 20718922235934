import React, { useState } from 'react';
import { Card, Header, Image } from 'semantic-ui-react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { HtmlEditor } from 'devextreme-react/html-editor';
import { BuyingButton } from '..';
import { Theme, Styles, Consts } from '../../res';

import packageImage from '../../res/images/package1.jpg';
import goldenPromise from '../../res/images/promises/golden.svg';
import silverPromise from '../../res/images/promises/silver.svg';
import contPromise from '../../res/images/promises/cont.svg';


const { f_16_700_text, f_14_700_text } = Styles;

const Package = ({ data }) => {
    const { t, i18n } = useTranslation();
    const { packageName, imgUrl, showOnly, validitySeconds, featureTxt, tags } = data;
    const [selected, setSelected] = useState(false);

    const isDemo = tags?.includes(Consts.PACKAGES_TAGS.DEMO);
    const isTopSeller = tags?.includes(Consts.PACKAGES_TAGS.TOP_SELL);
    const isFav = tags?.includes(Consts.PACKAGES_TAGS.PREFERRED);
    const isGolden = tags?.includes(Consts.PACKAGES_TAGS.GOLD_PROMIS);
    const isSilver = tags?.includes(Consts.PACKAGES_TAGS.SILVER_PROMIS);
    const isCont = tags?.includes(Consts.PACKAGES_TAGS.CONT_PROMIS);

    const showBanner = (isDemo || isFav || isTopSeller) && (isGolden || isCont || isSilver);

    return (
        <div className='first:tw-my-0 tw-mt-8 tw-w-full tw-break-inside-avoid tw-grid tw-place-items-center'>
            <Card
                style={{ borderRadius: 8, outline: selected ? "solid 2px white" : "none", boxShadow: selected ? '0px 8px 20px rgba(59, 45, 132, 0.64)' : 'none', backgroundColor: selected ? Theme.colors.PRIMARY_50 : Theme.colors.PRIMARY_05 }}
                onMouseEnter={() => setSelected(true)}
                onMouseLeave={() => setSelected(false)}
            >
                <Card.Content style={{ padding: 0, margin: 0, borderTopLeftRadius: 8, borderTopRightRadius: 8 }}>
                    <Image loading="lazy"
                        src={imgUrl || packageImage}
                        style={{ display: 'flex', height: 150, width: null, borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
                        fluid
                    />
                </Card.Content>

                {showBanner && <Card.Content style={{ border: 'none', backgroundColor: selected ? Theme.colors.WHITE : isDemo ? Theme.colors.GREY_30 : isTopSeller ? Theme.colors.SECONDARY_50 : isFav ? Theme.colors.PRIMARY_50 : "" }}>
                    <div className='tw-grid tw-grid-cols-3 tw-items-center'>
                        <div className='tw-col-span-2'>
                            <Header style={{ ...f_16_700_text, color: selected ? Theme.colors.PRIMARY_50 : Theme.colors.WHITE }}>
                                {
                                    isDemo ? t("DEMO") : isTopSeller ? t("TOP_SELL") : isFav ? t("PREFERRED") : ""
                                }
                            </Header>
                        </div>
                        <div className='tw-col-span-1 tw-relative'>
                            {(isGolden || isCont || isSilver) && <div className='tw-absolute -tw-translate-x-1/2 tw-border-GOLD tw-border tw-p-0.5 -tw-translate-y-1/2  tw-scale-150 tw-top-1/2 tw-left-1/2 tw-bg-white tw-rounded-full'>
                                <img src={isGolden ? goldenPromise : isSilver ? silverPromise : isCont ? contPromise : null} alt="promise" />
                            </div>}
                        </div>

                    </div>
                </Card.Content>}

                <Card.Content style={{ border: 'none' }}>
                    <Header style={{ ...f_14_700_text, color: selected ? Theme.colors.WHITE : Theme.colors.BLACK, margin: 0 }}>{packageName.toUpperCase()}</Header>
                    <HtmlEditor readOnly value={featureTxt} style={{ color: selected && Theme.colors.WHITE, border: 'none', boxShadow: 'none', padding: 0, margin: 0 }} />
                </Card.Content>

                {!showOnly && <Card.Content extra>
                    <div className='tw-grid-cols-5 tw-grid'>
                        <div className='tw-col-span-3 tw-flex tw-flex-row tw-items-center tw-gap-1'>
                            <BuyingButton mode="PRICE_DISCOUNT_AS_TEXT" data={data} selectedForHover={selected} />
                            <b style={{ ...f_14_700_text, color: selected ? Theme.colors.WHITE : Theme.colors.PRIMARY_50 }}>{`/ ${moment.duration(validitySeconds, 'seconds').locale(i18n.language).humanize()}`}</b>
                        </div>

                        <div className='tw-col-span-2'>
                            <BuyingButton mode="NO_PRICE_AS_BUTTON" selectedForHover={selected} data={data} />
                        </div>
                    </div>
                </Card.Content>}
            </Card>
        </div>
    );
};

export default Package;
