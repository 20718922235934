import React, { createRef, Suspense, useEffect } from 'react';
import { Sticky } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { WhastsappButton, NavBar, Footer, ScrollToTop, Spinner, PopupAd } from '../components';
import LandingStack from '../Routers/LandingStack';
import { Media } from '../Media';


const AdminLayout = ({ user }) => {

    const { inAuthLayout } = useSelector(state => state.configs);
    const divRef = createRef();

    useEffect(() => {
        if (!inAuthLayout) {
            const element1 = document.getElementById('gtagscirpt1');
            if (element1) {
                element1.remove();
            }

            const element2 = document.getElementById('gtagscirpt2');
            if (element2) {
                element2.remove();
            }

            // Create the script element
            var script = document.createElement('script');
            script.async = true;
            script.src = 'https://www.googletagmanager.com/gtag/js?id=G-F97DB3GKK9';
            script.setAttribute("id", "gtagscirpt1");

            // Append the script element to the document head
            document.head.appendChild(script);

            // Create the inline script element
            var inlineScript = document.createElement('script');

            inlineScript.setAttribute("id", "gtagscirpt2");
            inlineScript.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-F97DB3GKK9');
            `;

            // Append the inline script element to the document head
            document.head.appendChild(inlineScript);
        }

    });

    if (inAuthLayout) return (
        <Suspense fallback={<Spinner />}>
            <LandingStack />
        </Suspense>);

    return (
        <Suspense fallback={<Spinner />}>
            <Media lessThan='tablet'>
                {
                    (mediaClassNames, renderChildren) => {
                        return (
                            renderChildren && <div ref={divRef} className={mediaClassNames} style={{ position: 'relative', minHeight: '100vh' }}>
                                <ScrollToTop />
                                <Sticky context={divRef}>
                                    <NavBar />
                                </Sticky>
                                <LandingStack />
                                <Footer isMobile />
                                <WhastsappButton showSidebar={null} />
                                <PopupAd isLanding={true} />
                            </div>
                        );
                    }}
            </Media>

            <Media greaterThanOrEqual='tablet'>
                {
                    (mediaClassNames, renderChildren) => {
                        return (
                            renderChildren && <div ref={divRef} className={mediaClassNames} style={{ position: 'relative' }}>
                                <ScrollToTop />
                                <Sticky context={divRef}>
                                    <NavBar />
                                </Sticky>
                                <div className='tw-min-h-screen'>
                                    <LandingStack />
                                </div>
                                <Footer />
                                <WhastsappButton showSidebar={null} />
                                <PopupAd isLanding={true} />
                            </div>
                        );
                    }}
            </Media>
        </Suspense>
    );
};

export default AdminLayout;