import React from 'react';
import { Container, Grid, GridColumn, Header, List, Segment, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ContactList } from '..';
import { Theme, Styles } from '../../res';
import PATHS from '../../Routers/PATHS';

import logoImage from '../../res/images/logofooter.svg';

const { f_16_700_text, f_14_400_text, f_12_400_text } = Styles;

const Footer = ({ t, isMobile }) => {
    const isGoingExamRunning = useSelector(({ userExam }) => userExam.onGoingExam);
    const certs = useSelector(({ landingCerts }) => landingCerts.certs);

    if (isGoingExamRunning?.isMockExam) return null;

    return (
        <Segment basic style={{
            paddingTop: '10vh',
            margin: 0,
            width: "100%",
            backgroundColor: Theme.colors.PRIMARY_50,
        }}>

            <Container style={{ width: '85vw' }}>
                <Grid columns='5' stackable >
                    <GridColumn width='2'>
                        <Header as='h4' style={{ ...f_16_700_text, color: Theme.colors.WHITE }}>{t('SocialMedia')}</Header>
                        <List size='large' animated>
                            <List.Item style={{ ...f_14_400_text, color: Theme.colors.WHITE }} as='a' target="_blank" href='https://twitter.com/Learning_Go_Com'>{t("Twitter")}</List.Item>
                            <List.Item style={{ ...f_14_400_text, color: Theme.colors.WHITE }} as='a' target="_blank" href='https://www.linkedin.com/company/learning-go/'>{t('Linkedin')}</List.Item>
                            <List.Item style={{ ...f_14_400_text, color: Theme.colors.WHITE }} as='a' target="_blank" href='https://www.facebook.com/Learning-Go-101343875302322'>{t("Facebook")}</List.Item>
                            <List.Item style={{ ...f_14_400_text, color: Theme.colors.WHITE }} as='a' target="_blank" href='https://www.instagram.com/Learning_Go_Com/'>{t('Instagram')}</List.Item>
                            <List.Item style={{ ...f_14_400_text, color: Theme.colors.WHITE }} as='a' target="_blank" href='https://www.youtube.com/channel/UCve6GRUj0UkqtqbP8vFmexg'>{t('Youtube')}</List.Item>
                        </List>
                    </GridColumn>

                    <GridColumn width='2' textAlign='left'>
                        <Header as='h4' style={{ ...f_16_700_text, color: Theme.colors.WHITE }}>{t('ContactUs')}</Header>
                        <ContactList size='large' textColor={Theme.colors.WHITE} />
                    </GridColumn>

                    <GridColumn width='2'>
                        <Header as='h4' style={{ ...f_16_700_text, color: Theme.colors.WHITE }}>{t('UsefulLinks')}</Header>
                        <List size='large' animated>
                            <List.Item style={{ ...f_14_400_text, color: Theme.colors.WHITE }} as={Link} to='/blogs?tab=BLOGS'>{t('Blogs')}</List.Item>
                            <List.Item style={{ ...f_14_400_text, color: Theme.colors.WHITE }} as={Link} to='/blogs?tab=EVENTS'>{t('Events')}</List.Item>
                            <List.Item as={Link} to={PATHS.LANDING_TERMS_AND_CONDITIONS.URL} style={{ ...f_14_400_text, color: Theme.colors.WHITE }}>{t('TermsAndConditions')}</List.Item>
                            <List.Item as={Link} to={PATHS.LANDING_PRIVACY_POLICY.URL} style={{ ...f_14_400_text, color: Theme.colors.WHITE }}>{t('PrivacyPolicy')}</List.Item>
                            <List.Item as={Link} to={PATHS.LANDING_COMPANIES.URL} style={{ ...f_14_400_text, color: Theme.colors.WHITE }}>{t('ForBusiness')}</List.Item>
                            <List.Item as={Link} to={PATHS.LANDING_PROMISES.URL} style={{ ...f_14_400_text, color: Theme.colors.WHITE }}>{t('Promises')}</List.Item>
                        </List>
                    </GridColumn>


                    <GridColumn width='4'>
                        <Header as='h4' style={{ ...f_16_700_text, color: Theme.colors.WHITE }}>{t('QuickLinks')}</Header>
                        <List size='large' animated>
                            {
                                certs.slice(0, 8).map(c => <List.Item key={c.id} style={{ ...f_14_400_text, color: Theme.colors.WHITE }} as={Link} to={`${PATHS.LANDING_CERTIFICATIONS.URL}/${c.id}`}>{c.code}</List.Item>
                                )
                            }
                        </List>
                    </GridColumn>

                    <GridColumn width='6' textAlign='center'>
                        <Image loading="lazy" src={logoImage} centered size='medium' style={{ transform: !isMobile && 'scale(1.4)' }} />
                    </GridColumn>
                </Grid>

                <Header style={{ ...f_12_400_text, marginBottom: 0, color: Theme.colors.WHITE }}>{t("AllRightText")}</Header>
                <Header style={{ ...f_12_400_text, marginBottom: 0, color: Theme.colors.WHITE }}>{t("PowerdByText")}</Header>
                <Header style={{ ...f_12_400_text, marginTop: 0, color: Theme.colors.WHITE }}>{`© ${new Date().getFullYear()} ${t('AllRightsReserved')}`}</Header>
            </Container>
        </Segment >
    );
};

export default withTranslation()(Footer);