import React, { useEffect, useState } from 'react';
import { Accordion, Button, Checkbox, Divider, Form, FormField, Grid, GridColumn, GridRow, Header, Icon, Input, Menu, Segment } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import request from '../../api';
import { ErrorSegment } from '..';
import history from '../../history';
import PATHS from '../../Routers/PATHS';
import { Styles, Theme, Consts } from '../../res';

const { solidBtn, f_20_400_text, f_20_700_text, f_40_700_text, f_12_700_text } = Styles;

const PaymentForm = (props) => {
    const { data, t, onBuySuccess } = props;
    const { packageName, user, priceUSD, discountUSD, id, name, validitySeconds, policyId } = data;
    const [copon, setCopon] = useState('');
    const [coponDiscount, setCoponDiscount] = useState(0);
    const [coponLoading, setCoponLoading] = useState(false);
    const [coponError, setCoponError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [checkPolicy, setCheckPolicy] = useState({ activeIndex: -1, checked: false, content: null });

    const getPolicyContent = async () => {
        if (policyId) {
            const { data: { data } } = await request.get(`/public/policy?policyId=${policyId}`);
            setCheckPolicy(p => ({ ...p, content: data?.[0]?.content }));
        }
    };

    useEffect(() => {
        getPolicyContent();
        //eslint-disable-next-line
    }, []);

    const checkCopon = async () => {
        setCoponLoading(true);
        try {
            const prmootionData = (await request.get(`/supscription/promotion/${copon}/details`)).data;

            if (prmootionData.discountUSD) {
                setCoponDiscount(!prmootionData.subscribePackageId || id === prmootionData.subscribePackageId ?
                    (prmootionData.discountType === Consts.DISCOUNT_TYPES.AMOUNT ? prmootionData.discountUSD : ((priceUSD - discountUSD) * prmootionData.discountUSD / 100)) : 0);
            }
            else {
                setCoponDiscount(0);
            }
        } catch (err) {
            setCoponError(err);
        } finally {
            setCoponLoading(false);
        }
    };

    const subscripe = async () => {
        setLoading(true);
        try {
            const { data } = await request.post('/student/subscribe', { subscriptionPackageId: id, promotionCode: copon?.trim() });
            if (getTotal() > 0) {
                window.open(`https://www.q-tickets.com/payfortusd.aspx?paymentId=${data.paymentId}&amountUSD=${getTotal()}&referenceId=${Math.floor(Math.random() * 10000) + 1}&customerName=${user.firstName + " " + user.lastName}&customerContactNumber=${user.contactNumber}&customerEmail=${user.email}&courseName=${packageName || name}&courseDescription=`, '_self');
            } else onBuySuccess();
        } catch (err) {
            if (err.response) {
                if (err.response.data.code && err.response.data.code === "UserHasValidSubscriptionOnPackage")
                    setError(t('AlreadySubscribed'));
                else if (err.response.data.code && err.response.data.code === "UserHasNotCompleteSubscriptionOnPackage")
                    setError(t('AlreadySubscribedButNotPaid'));
            }
            else setError(err);
        } finally {
            setLoading(false);
        }
    };

    const getTotal = () => {
        const total = (priceUSD || 0) - (discountUSD || 0) - (coponDiscount || 0);
        if (total <= 0) return 0;
        return total;
    };

    if (error) return <Segment>
        <ErrorSegment text={error} />
        <Divider hidden />
        <Button fluid style={solidBtn} content={t('Subscriptions')} onClick={() => history.push(PATHS.USER_SUBSCRIPTIONS.URL)} />
    </Segment>;

    return (
        <Segment basic loading={loading}>
            <Header textAlign='center' style={f_40_700_text}>{`${packageName || name}`.toUpperCase()}</Header>
            <Grid columns='2' verticalAlign='middle'>
                <GridRow>
                    <GridColumn>
                        <Header style={f_20_400_text}>{`${t('Validity')}`}</Header>
                    </GridColumn>
                    <GridColumn textAlign='right'>
                        <Header style={f_20_400_text} >{`${validitySeconds / 60 / 60 / 24} ${t('Day')}`}</Header>
                    </GridColumn>
                </GridRow>
                <GridRow>
                    <GridColumn>
                        <Header style={f_20_400_text}>{`${t('Price')}`}</Header>
                    </GridColumn>
                    <GridColumn textAlign='right'>
                        <Header style={f_20_400_text} >{`${(priceUSD || 0)} $`}</Header>
                    </GridColumn>
                </GridRow>
                <GridRow>
                    <GridColumn>
                        <Header style={f_20_400_text}>{`${t('Discount')}`}</Header>
                    </GridColumn>
                    <GridColumn textAlign='right'>
                        <Header style={f_20_400_text} >{`${discountUSD || 0} $`}</Header>
                    </GridColumn>
                </GridRow>
                <GridRow>
                    <GridColumn width='10'>
                        <Form>
                            <FormField
                                control={Input}
                                placeholder={t('Copon')}
                                value={copon}
                                onChange={(e, { value }) => setCopon(value)}
                                error={coponError}
                                onFocus={() => setCoponError(null)}
                            />
                        </Form>
                    </GridColumn>
                    <GridColumn width='6'>
                        <Button disabled={!copon} style={{ ...solidBtn, backgroundColor: Theme.colors.SECONDARY_50, ...f_12_700_text }} fluid onClick={checkCopon} loading={coponLoading}>{t('UseCopon')}</Button>
                    </GridColumn>
                </GridRow>
                <GridRow>
                    <GridColumn>
                        <Header style={f_20_400_text}>{`${t('CoponDiscount')}`}</Header>
                    </GridColumn>
                    <GridColumn textAlign='right'>
                        <Header style={f_20_400_text} >{`${(coponDiscount || 0)} $`}</Header>
                    </GridColumn>
                </GridRow>
                <GridRow>
                    <GridColumn>
                        <Header style={{ ...f_20_700_text, color: Theme.colors.PRIMARY_50 }}>{`${t('Total')}`}</Header>
                    </GridColumn>
                    <GridColumn textAlign='right'>
                        <Header style={{ ...f_20_700_text, color: Theme.colors.PRIMARY_50 }} >{`${getTotal()} $`}</Header>
                    </GridColumn>
                </GridRow>
            </Grid>
            <Header as='h5' icon textAlign='center' style={{ margin: 0, color: Theme.colors.SECONDARY_50 }}>
                <Icon name='shield' circular style={{ color: Theme.colors.SECONDARY_50 }} />
                <Header.Content>{t("EnsuringPaymentMessage")}</Header.Content>
            </Header>
            <Divider hidden />
            {policyId && <Accordion
                as={Menu}
                vertical
                fluid
                style={{ backgroundColor: "transparent", border: 'none', boxShadow: 'none' }}
            >
                <Menu.Item style={{ backgroundColor: Theme.colors.WHITE, border: 'none', boxShadow: 'none' }}>
                    <Accordion.Title
                        index={1}
                        active={checkPolicy.activeIndex === 1}
                        content={<Checkbox onChange={(e, { checked }) => { e.stopPropagation(); setCheckPolicy(p => ({ ...p, checked })); }} label={{ children: t("IAgreeOnPackagePolicy") }} />}
                        onClick={(_, { index }) => setCheckPolicy(p => ({ ...p, activeIndex: checkPolicy.activeIndex === index ? -1 : index }))}
                    />
                    <Accordion.Content active={checkPolicy.activeIndex === 1} content={<div className='tw-mt-5' dangerouslySetInnerHTML={{ __html: checkPolicy.content }} />} />

                </Menu.Item>

            </Accordion>}

            <Button disabled={id === 'null' || (policyId && !checkPolicy.checked)} style={solidBtn} fluid content={t('Buy').toUpperCase()} onClick={subscripe} />
        </Segment>
    );
};

export default withTranslation()(PaymentForm);