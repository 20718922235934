import React, { useRef, useState } from 'react';
import { Dropdown, Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ReCAPTCHA from "react-google-recaptcha";
import { Styles } from '../../res';
import request from '../../api';
import { toast } from 'react-toastify';
import { toastOptions } from '../../helpers/general';

const { solidBtn } = Styles;

const CompanyForm = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [country, setCountry] = useState(1);
    const [mobile, setMobile] = useState("");
    const [people, setPeople] = useState(null);
    const [companyName, setCompanyName] = useState("");
    const [companySize, setCompanySize] = useState(null);
    const [jobTitle, setJobTitle] = useState("");
    const [jobLevel, setJobLevel] = useState(null);
    const [note, setNote] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const recaptchaRef = useRef();

    const countries = useSelector(state => state.countries.countriesData);

    const companySizeOptions = [
        { key: 1, text: t("Small"), value: "SMALL" },
        { key: 2, text: t("Medium"), value: "MID" },
        { key: 3, text: t("Enterprise"), value: "LARG" },
    ];

    const jobLevelOptions = [
        { key: 1, text: t("Entry"), value: "ENTRY" },
        { key: 2, text: t("Mid"), value: "MID" },
        { key: 3, text: t("Senior"), value: "SENIOR" },
        { key: 4, text: t("Manager"), value: "MANAGER" },
        { key: 5, text: t("Owner"), value: "OWNER" },
    ];

    const peopleOptions = [
        { key: 1, value: 1, text: "1" },
        { key: 3, value: 3, text: "3" },
        { key: 5, value: 5, text: "+5" },
        { key: 10, value: 10, text: "+10" },
        { key: 25, value: 25, text: "+25" },
        { key: 50, value: 50, text: "+50" },
        { key: 100, value: 100, text: "+100" },
    ];

    const clear = () => {
        setCompanyName('');
        setEmail('');
        setMobile('');
        setCompanySize(null);
        setCountry(1);
        setFirstName('');
        setLastName('');
        setJobTitle('');
        setJobLevel(null);
        setPeople(null);
        setNote('');
    };


    const validate = () => {
        if (!firstName) {
            setError({ ...error, firstName: t('Required') });
            return false;
        }
        if (!lastName) {
            setError({ ...error, lastName: t('Required') });
            return false;
        }

        if (!companyName) {
            setError({ ...error, companyName: t('Required') });
            return false;
        }

        if (!companySize) {
            setError({ ...error, companySize: t('Required') });
            return false;
        }

        if (!people) {
            setError({ ...error, people: t('Required') });
            return false;
        }

        if (!jobTitle) {
            setError({ ...error, jobTitle: t('Required') });
            return false;
        }

        if (!jobLevel) {
            setError({ ...error, jobLevel: t('Required') });
            return false;
        }

        if (!country) {
            setError({ ...error, country: t('Required') });
            return false;
        }
        if (!mobile) {
            setError({ ...error, mobile: t('Required') });
            return false;
        }
        const validPhone = /[0-9]{8,}$/;
        if (!validPhone.test(mobile)) {
            setError({ ...error, mobile: t('MobileError') });
            return false;
        }
        if (!email) {
            setError({ ...error, email: t('Required') });
            return false;
        }

        if (!note) {
            setError({ ...error, note: t('Required') });
            return false;
        }
        const validEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!validEmail.test(email)) {
            setError({ ...error, email: t('EmailError') });
            return false;
        }
        setError('');
        return true;
    };
    const onSavePressed = async () => {
        try {
            setLoading(true);
            if (validate()) {
                let token;
                token = await recaptchaRef.current.getValue();
                if (!token) token = await recaptchaRef.current.executeAsync();
                await request.post('/public/company/offer', {
                    firstName, lastName, companyName, companySize, traineeCnt: people,
                    email, phone: mobile, jobTitle, jobLevel, trainNeeds: note,
                    countryId: country, "g-recaptcha-response": token
                });
                clear();
                toast.success(t("CompanyFormSuccessMsg"), toastOptions());
            }
        } catch (error) {
            toast.error(t("CompanyFormError"), toastOptions());
        } finally {
            setLoading(false);
        }
    };

    // const renderErrorMsg = () => {
    //     if (!error) return;
    //     return <Message negative icon='x' header={t('Error')} content={error} />;
    // };

    return (
        <div basic clearing className='tw-bg-WHITE tw-p-10 tw-mx-auto tw-rounded-3xl tw-max-w-3xl'>
            {/* {renderErrorMsg()} */}
            <Form className='tw-grid md:tw-grid-cols-3 tw-gap-5'>
                <Form.Input
                    fluid
                    type='text'
                    label={t('CompanyName')}
                    placeholder={t('Name')}
                    required
                    maxLength={20}
                    value={companyName}
                    onChange={(e, { value }) => setCompanyName(value)}
                    onFocus={() => setError('')}
                    error={error.companyName}
                />

                <Form.Field
                    fluid
                    label={t('CompanySize')}
                    control={Dropdown}
                    options={companySizeOptions}
                    placeholder={t('Select')}
                    required
                    value={companySize}
                    onChange={(e, { value }) => setCompanySize(value)}
                    onFocus={() => setError('')}
                    error={error.companySize}
                    selection
                    search
                />

                <Form.Field
                    fluid
                    label={t('NumberPeopleTrain')}
                    control={Dropdown}
                    options={peopleOptions}
                    placeholder={t('Select')}
                    required
                    value={people}
                    onChange={(e, { value }) => setPeople(value)}
                    onFocus={() => setError('')}
                    error={error.people}
                    selection
                    search
                />

                <Form.Input
                    fluid
                    type='text'
                    label={t('FirstName')}
                    placeholder={t('John')}
                    required
                    maxLength={20}
                    value={firstName}
                    onChange={(e, { value }) => setFirstName(value)}
                    onFocus={() => setError('')}
                    error={error.firstName}
                />
                <Form.Input
                    fluid
                    type='text'
                    label={t('LastName')}
                    placeholder={t('Doe')}
                    required
                    maxLength={20}
                    value={lastName}
                    onChange={(e, { value }) => setLastName(value)}
                    onFocus={() => setError('')}
                    error={error.lastName}
                />

                <Form.Field
                    fluid
                    required
                    label={t('Country')}
                    options={countries}
                    control={Dropdown}
                    onChange={(e, { value }) => setCountry(value)}
                    noResultsMessage={t('NotFound')}
                    placeholder={t('Country')}
                    search
                    selection
                    defaultValue={country ? countries?.find(c => c?.value === country)?.value : null}
                    error={error.country}
                    onFocus={() => setError('')}
                />

                <Form.Input
                    fluid
                    type='email'
                    label={t('WorkEmail')}
                    placeholder='example@ex.com'
                    required
                    maxLength={50}
                    value={email}
                    onChange={(e, { value }) => setEmail(value.toLowerCase())}
                    onFocus={() => setError('')}
                    error={error.email}
                />

                <Form.Input
                    fluid
                    type='text'
                    label={t('JobTitle')}
                    placeholder={t("Title")}
                    required
                    maxLength={50}
                    value={jobTitle}
                    onChange={(e, { value }) => setJobTitle(value)}
                    onFocus={() => setError('')}
                    error={error.jobTitle}
                />

                <Form.Field
                    fluid
                    label={t('JobLevel')}
                    control={Dropdown}
                    options={jobLevelOptions}
                    placeholder={t('Select')}
                    required
                    value={jobLevel}
                    onChange={(e, { value }) => setJobLevel(value)}
                    onFocus={() => setError('')}
                    error={error.jobLevel}
                    selection
                    search
                />

                <Form.Input
                    label={t('Code')}
                    value={country ? countries?.find(c => c?.value === country)?.code : null}
                    required
                />
                <Form.Input
                    label={t('Mobile')}
                    value={mobile}
                    required
                    onChange={(e, { value }) => setMobile(value)}
                    type='phone'
                    placeholder='xxx-xxx-xxx'
                    maxLength={15}
                    onFocus={() => setError('')}
                    error={error.mobile}
                    className='md:tw-col-span-2'
                />

                <Form.TextArea
                    label={t('WhatComnayNeeds')}
                    value={note}
                    required
                    className='md:tw-col-span-3'
                    onFocus={() => setError('')}
                    onChange={(e, { value }) => setNote(value)}
                />

                <Form.Field>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={process.env.REACT_APP_RECAPTCH_KEY}
                    />
                </Form.Field>

                <Form.Button loading={loading} floated='right' className='tw-mt-10 md:tw-col-span-3' onClick={onSavePressed} style={{ ...solidBtn }}>{t('Submit').toUpperCase()}</Form.Button>
            </Form>
        </div>
    );
};



export default CompanyForm;