import React, { useEffect, useState } from 'react';
import { Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { BlockViewer, ErrorSegment, Spinner } from '..';
import request from '../../api';
import { Styles } from '../../res';

const { noExtraSpaces } = Styles;

const BlogInfo = ({ blogId }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [data, setData] = useState(null);

    const getBlogDetails = async () => {
        try {
            setLoading(true);
            const { data: { data } } = await request.get(`/public/blogs?blogId=${blogId}`);
            setData(data[0]);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (blogId) getBlogDetails();
        //eslint-disable-next-line
    }, []);

    if (loading) return <Spinner />;
    if (error) return <ErrorSegment />;
    if (!data) return <ErrorSegment text={t("ComingSoon")} icon="clock" />;


    return (
        <Segment style={{ ...noExtraSpaces, border: 'none', boxShadow: 'none' }}>
            <div className='md:tw-max-w-screen-md md:tw-mx-auto blog-text'>
                <BlockViewer value={data?.contentJson} meta={data} />
            </div>
        </Segment>
    );

};

export default BlogInfo;