import React, { useState } from 'react';
import { Segment, Grid, GridColumn, Icon, Header, Image, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { HtmlEditor, TableContextMenu, TableResizing } from 'devextreme-react/html-editor';
import McqsQuestionTemplate from '../Templates/McqsQuestionTemplate';
import TextQuestionTemplate from '../Templates/TextQuestionTemplate';
import McqsQuestionMockTemplate from '../Templates/McqsQuestionMockTemplate';
import { ErrorSegment, ReportForm, Separator } from '../../..';
import { Consts, Styles } from '../../../../res';
import iconImage from '../../../../res/images/logo.svg';
import { replaceFontFamily } from '../../../../helpers/general';

const { noExtraSpaces } = Styles;

const QuestionPage = (props) => {
    const { Q, Qindex, FactIndex, onAnswerQuestion, loading, error, isMockExam, isViewOnly, user, onHideSomeAnswers, onExcludeOption } = props;

    const { t } = useTranslation();
    const [showReportIssue, setShowReportIssue] = useState(false);

    if (loading || !Q) return <Segment basic style={{ height: '40vh', ...noExtraSpaces }} loading={loading} />;
    if (error) return <ErrorSegment />;

    const renderMcqsTemplate = () => {
        if (Q.questionTypeId === Consts.QUESTIONS_TYPES.MCQS) {
            if (isMockExam)
                return <McqsQuestionMockTemplate Q={Q} onAnswerQuestion={onAnswerQuestion} isViewOnly={isViewOnly} onExcludeOption={onExcludeOption} />;
            else return <McqsQuestionTemplate Q={Q} onAnswerQuestion={onAnswerQuestion} isViewOnly={isViewOnly} onHideSomeAnswers={onHideSomeAnswers} onExcludeOption={onExcludeOption} />;
        }
        return null;

    };

    return (
        <Segment basic style={noExtraSpaces}>
            <div style={{ overflow: 'auto', height: '100%', paddingBottom: '80px', width: '100%' }} className='question-body'>

                <Grid columns='equal' style={noExtraSpaces}>
                    <GridColumn >
                        <div className="widget-container" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div textAlign='right'>
                                {!!Q.isFlagged && <Icon name='flag' inverted color='blue' />}
                                <Header as='h1' color='blue' style={{ marginTop: 5 }}>{Q.factId ? FactIndex + 1 : Qindex + 1}.</Header>
                            </div>
                            {Q.factId &&
                                <HtmlEditor value={replaceFontFamily(Q.factContent.trim())} readOnly style={{ backgroundColor: '#D9EDF6', minHeight: '35vh', height: '35vh', width: '97%' }} className='resizo' >
                                    <TableContextMenu enabled />
                                    <TableResizing enabled />
                                </HtmlEditor>
                            }
                            <Separator h='1vh' />
                            <HtmlEditor value={replaceFontFamily(Q.questionText.trim())} readOnly style={{ backgroundColor: '#D9EDF6', width: '95%' }} className='resizo' >
                                <TableContextMenu enabled />
                                <TableResizing enabled />
                            </HtmlEditor>
                            {!isMockExam && <Icon size='large' title={t('ReportProblem')} name='exclamation triangle' color='red' link onClick={() => setShowReportIssue(true)} />}
                        </div>
                        {renderMcqsTemplate()}
                        {Q.questionTypeId === Consts.QUESTIONS_TYPES.ESSAY && <TextQuestionTemplate Q={Q} onAnswerQuestion={onAnswerQuestion} isMockExam={isMockExam} isViewOnly={isViewOnly} />}
                    </GridColumn>
                </Grid>


                <div className='studentExamWatermark' style={{ top: '40%', left: '40%' }}>
                    <Image loading="lazy" size='medium' src={iconImage} />
                </div>

                <Header
                    as='h1'
                    content={`${user.firstName} ${user.lastName} [${user.email}] ${user.contactNumber}`}
                    className='studentExamWatermark'
                />
            </div>

            <Modal
                onClose={() => setShowReportIssue(false)}
                open={showReportIssue}
                content={<ReportForm reportOn={Consts.REPORTS_TYPES.QUESTION} reportLink={Q} id={Q.questionId} showModal={setShowReportIssue} />}
                closeIcon
                header={t('ReportProblem')}
                size='small'
                centered
                closeOnDimmerClick={false}
            />
        </Segment>
    );
};

export default QuestionPage;