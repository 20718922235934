import React, { useEffect, useState } from 'react';
import { Segment, Icon, Pagination, Header, Image } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { ErrorSegment, SuccessStoryCard } from '..';
import request from '../../api';
import starsLogoImage from '../../res/images/stars_logo.svg';

const SuccessStoriesList = ({ id, isCert, all }) => {
    const { t } = useTranslation();
    const [stories, setStories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const pageSize = 3;
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(stories.length / pageSize);

    const indexOfLastItem = currentPage * pageSize;
    const indexOfFirstItem = indexOfLastItem - pageSize;
    const currentStories = stories.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (e, { activePage }) => {
        setCurrentPage(activePage);
    };


    useEffect(() => {
        const getStoriesList = async () => {
            setLoading(true);
            try {
                const { data: { data } } = all ? await request('/public/success_stories') : await request(`/public/success_stories?${isCert ? 'certificateId' : 'courseId'}=${id}`);
                setStories(data || []);
            } catch (error) {
                setError(error);
            }
            finally {
                setLoading(false);
            }
        };
        getStoriesList();
        // eslint-disable-next-line
    }, []);


    if (error) return <ErrorSegment />;
    if (!stories.length) return <Segment basic textAlign='center' loading={loading}>
        <Image src={starsLogoImage} centered />
        <Header>{t("EmptySuccessStories")}</Header>
    </Segment>;

    return (
        <Segment basic loading={loading}>

            {
                currentStories.map(s => <SuccessStoryCard key={s.id} name={s.name} quote={s.content} img={s.imgUrl} />)
            }

            <div className='tw-my-10 tw-flex tw-flex-row tw-justify-center tw-items-center'>
                <Pagination
                    defaultActivePage={1}
                    onPageChange={handlePageChange}
                    ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                    firstItem={null}
                    lastItem={null}
                    prevItem={{ content: <Icon name='angle left' />, icon: true }}
                    nextItem={{ content: <Icon name='angle right' />, icon: false }}
                    boundaryRange={1}
                    siblingRange={1}
                    totalPages={totalPages}
                />
            </div>

        </Segment>
    );
};

export default SuccessStoriesList;
