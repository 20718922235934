import {
    COUNTRIES_INFO_CHANGED,
    COUNTRIES_OPERATION_START,
    COUNTRIES_OPERATION_FAIL,
    COUNTRIES_OPERATION_FINISH,
    COUNTRIES_SUCCESS
} from '../../actions/types';

const INITIAL_STATE = {
    error: '',
    loading: false,
    countriesData: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case COUNTRIES_INFO_CHANGED:
            return { ...state, [action.payload.props]: action.payload.value };
        case COUNTRIES_OPERATION_START:
            return { ...state, loading: true };
        case COUNTRIES_OPERATION_FINISH:
            return { ...state, loading: false };
        case COUNTRIES_OPERATION_FAIL:
            return { ...state, error: { [action.payload.props]: action.payload.value } };
        case COUNTRIES_SUCCESS:
            return { ...state, countriesData: prepareData(action.payload) };
        default:
            return state;
    }
};

const prepareData = (countries) => {
    return countries.filter(item => ![208, 192, 191, 138, 118, 111, 9, 57, 92].includes(item.id)).map(c => {
        return {
            key: c.id,
            // flag: c.isoCode.toLowerCase(),
            value: c.id,
            text: c.name,
            code: c.phoneCode
        }
    })
}