import React, { useEffect, useState } from 'react';
import { Grid, GridColumn, Icon } from 'semantic-ui-react';
import FileUploader from 'devextreme-react/file-uploader';
import { BASE_URL } from '../../api';

const FilesUploader = ({ data, Url, isSingle, isGeneral }) => {
    const [showFilesList, setShowFilesList] = useState(true);
    const uploadUrl = Url ? `${BASE_URL}${Url}` : `${BASE_URL}/doc/upload`;
    const allowedFileExtensions = ['.pdf', '.docx', '.doc', '.pptx', '.ppt', '.xlsx', '.xls'];

    useEffect(() => {
        let open = XMLHttpRequest.prototype.open;
        XMLHttpRequest.prototype.open = function () {
            open.apply(this, arguments);
            this.withCredentials = true;
        };
    }, []);

    const onUploaded = (e) => {
        // const file = e.file;
        // const fileReader = new FileReader();
        // fileReader.readAsDataURL(file);
        // fileReader.onload = () => {
        // };

        if (isSingle)
            data.setValue(JSON.parse(e.request.response).docUrl);
        else data.setValue([...data.value, JSON.parse(e.request.response).docUrl]);
    };

    return (
        <div>
            <Grid columns='2' stackable verticalAlign='middle'>
                <GridColumn width='13'>
                    <FileUploader
                        multiple={!isSingle}
                        accept="application/pdf,.pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.docx,application/msword,.doc,application/vnd.openxmlformats-officedocument.presentationml.presentation,.pptx,application/vnd.ms-powerpoint,.ppt,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xlsx,application/vnd.ms-excel,.xls"
                        allowedFileExtensions={allowedFileExtensions}
                        uploadMode="instantly"
                        uploadUrl={uploadUrl}
                        showFileList={showFilesList}
                        allowCanceling
                        visible
                        name='doc'
                        activeStateEnabled
                        uploadCustomData={{
                            accessTerm: isGeneral && 'PUBLIC'
                        }}
                        onUploaded={onUploaded}
                        onFilesUploaded={isSingle ? null : () => setShowFilesList(false)}
                        onValueChanged={() => setShowFilesList(true)}
                    />
                </GridColumn>

                {isSingle && data.value && <GridColumn width='3'>
                    <a title={data.value} href={data.value} target='_blank' rel='noreferrer'><Icon size='big' name='file alternate' link /></a>
                </GridColumn>}

            </Grid>
        </div>
    );
};



export default FilesUploader;